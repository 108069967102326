import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFax,
  faPhoneSquareAlt,
  faFileArchive,
  faFileInvoice,
  faFile,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import Fade from "react-reveal/Fade"
import "./dead-ends.scss"
import { ServiceCard } from "../components/service-card/service-card"
import { Slogan } from "../components/slogan/slogan"
import { SloganFlipper } from "../components/slogan/slogan-flipper"
import { FullScreenModal } from "../components/full-screen-modal/full-screen-modal"
import { GlobalStateProvider } from "../utils/globalStateProvider"

export default function NegligenciaReclamacionViable() {
  return (
    <GlobalStateProvider>
      <React.Fragment>
        <SEO title="Reclamación viable" />
        <Layout pageId="deadend-moral-damage">
          <div className="deadend-container">
            <h1>Resultado del cuestionario</h1>
            <h2>¡Tu reclamación tiene buena pinta!</h2>
            <p>
              Con <strong>negligenciasdeabogados.net</strong> te ayudamos a
              continuar con el proceso. Hay dos caminos: obtener un dictamen con
              validez jurídica o de momento sólo consultar a uno de nuestros
              expertos.
            </p>

            <center className="button-container">
              <Fade left duration={2000}>
                <ul className="cards grid grid--desktop-2cols grid--smartphone-1cols">
                  <li key="consulta-experto">
                    <ServiceCard
                      title="Te llamamos 30"
                      key="consulta-experto"
                      featuredImage={
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          color="#fcc831"
                          size="2x"
                        />
                      }
                      featureList={[
                        "Servicio <strong>premium</strong>",
                        "Un <strong>experto</strong> te llamará (tfno/whatsapp)",
                        // "<strong>30 minutos</strong> por <strong class='price'>30€</strong>",
                      ]}
                      ctaLink="/consulta-experto"
                    />
                  </li>
                  <li key="contratar-dictamen">
                    <ServiceCard
                      title="Dictamen"
                      key="contratar-dictamen"
                      featuredImage={
                        <FontAwesomeIcon
                          icon={faFileArchive}
                          color="#fcc831"
                          size="2x"
                        />
                      }
                      featureList={[
                        "Documento <strong>premium</strong>",
                        "<strong>Validez jurídica</strong>",
                        // "<strong class='price'>30€</strong> (consulta) + <strong class='price'>140€</strong>",
                      ]}
                      ctaLink="/encargo-dictamen"
                    />
                  </li>
                </ul>
              </Fade>
            </center>

            <SloganFlipper></SloganFlipper>
          </div>
          {/* <h2>
                    (pantalla que previa explicación de no poder seguir con el
                    formulario redirige a “introducción/presentación” mediante
                    enlace con hipervínculo reparación moral para el cliente +
                    opción comprar consulta telefónica “cuéntanos”)
                </h2> */}
          <FullScreenModal />
        </Layout>
      </React.Fragment>
    </GlobalStateProvider>
  )
}
